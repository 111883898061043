// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("/opt/build/repo/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-clients-js": () => import("/opt/build/repo/src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-consulting-js": () => import("/opt/build/repo/src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-angular-js": () => import("/opt/build/repo/src/pages/courses/angular.js" /* webpackChunkName: "component---src-pages-courses-angular-js" */),
  "component---src-pages-courses-react-redux-js": () => import("/opt/build/repo/src/pages/courses/react-redux.js" /* webpackChunkName: "component---src-pages-courses-react-redux-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hub-js": () => import("/opt/build/repo/src/pages/hub.js" /* webpackChunkName: "component---src-pages-hub-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("/opt/build/repo/src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-bitsofgold-js": () => import("/opt/build/repo/src/pages/projects/bitsofgold.js" /* webpackChunkName: "component---src-pages-projects-bitsofgold-js" */),
  "component---src-pages-projects-farmdog-js": () => import("/opt/build/repo/src/pages/projects/farmdog.js" /* webpackChunkName: "component---src-pages-projects-farmdog-js" */),
  "component---src-pages-projects-jfrog-js": () => import("/opt/build/repo/src/pages/projects/jfrog.js" /* webpackChunkName: "component---src-pages-projects-jfrog-js" */),
  "component---src-pages-projects-mimic-js": () => import("/opt/build/repo/src/pages/projects/mimic.js" /* webpackChunkName: "component---src-pages-projects-mimic-js" */),
  "component---src-pages-projects-phytech-js": () => import("/opt/build/repo/src/pages/projects/phytech.js" /* webpackChunkName: "component---src-pages-projects-phytech-js" */),
  "component---src-pages-projects-proggio-js": () => import("/opt/build/repo/src/pages/projects/proggio.js" /* webpackChunkName: "component---src-pages-projects-proggio-js" */),
  "component---src-pages-projects-thetaray-js": () => import("/opt/build/repo/src/pages/projects/thetaray.js" /* webpackChunkName: "component---src-pages-projects-thetaray-js" */),
  "component---src-pages-projects-unpakt-js": () => import("/opt/build/repo/src/pages/projects/unpakt.js" /* webpackChunkName: "component---src-pages-projects-unpakt-js" */),
  "component---src-pages-talks-js": () => import("/opt/build/repo/src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-testimonials-js": () => import("/opt/build/repo/src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-training-js": () => import("/opt/build/repo/src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

